import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import MainVisual from "../components/main-visual"
import SectionCapabilities from "../components/home/section-capabilities"
import IconArrow from "../assets/arrow-right.svg"
// import IconSliderLeft from "../assets/arrow-slide-left.svg"
// import IconSliderRight from "../assets/arrow-slide-right.svg"
import IconStar from "../assets/star.svg"
// import Slider from "react-slick"
import IconScroll from "../assets/arrow-scroll-down.svg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = () => {
  const firstSection = React.useRef()
  const scrollDown = () => {
    window.scrollTo({
      top: firstSection.current.offsetTop,
      behavior: 'smooth',
    })
  }
  // const slickSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   nextArrow: <IconSliderRight />,
  //   prevArrow: <IconSliderLeft />
  // };

  return (
    <Layout>
      <MainVisual
        title="When Machine Meets Human"
        description="Bring your business to the next level through the use of analytics and machine learning"
        buttonText="Contact Us"
        buttonLink="/contact-us"
      >
        <StaticImage src="../images/home-main-visual.jpg" className="w-full" alt="main visual"  />
        <button type="button" className="absolute z-30 left-0 right-0 bottom-4 mx-auto animate-bounce hidden sm:inline-block" onClick={scrollDown}>
          <IconScroll className="mx-auto" />
        </button>
      </MainVisual>
      <SectionCapabilities ref={firstSection} />
      <section className="bg-white py-8 md:py-20">
        <div className="container">
          <h3 className="text-primary text-2xl md:text-5xl font-semibold text-center mb-6 md:mb-12">Improving your Business through Technology has Never Been Easier (or More Necessary)</h3>
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-16 mb-12 md:mb-24 items-center">
            <StaticImage src="../images/home-about-1.png" className="flex-shrink-0 md:order-last max-h-60 max-w-sm" layout="constrained" objectFit="contain" alt="about" />
            <div className="text-center md:text-left">
              {/* <h4 className="text-primary text-xl md:text-4xl font-semibold mb-2">A Key Fact that Defines Modernity</h4>*/}
              <p className="text-sm md:text-base mb-3 md:mb-6">We live in an era of rapid digitization and technological progress; more importantly, those changes occur at a rate traditional workflows will find difficult to keep up with.</p>
              <p className="text-sm md:text-base mb-3 md:mb-6">Let us help you utilize analytics and machine learning to improve your business.</p>
              <Link to="/our-capabilities" className="text-sm md:text-base inline-flex items-center bg-primary text-white py-2 px-4">
                Our Solutions
                <IconArrow className="inline ml-2 fill-white" />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-16 mb-12 md:mb-24 items-center">
            <StaticImage src="../images/home-about-2.png" className="flex-shrink-0 max-h-60 max-w-sm" layout="constrained" objectFit="contain" alt="about" />
            <div className="text-center md:text-left">
              {/* <h4 className="text-primary text-xl md:text-4xl font-semibold mb-2">We are Highly Driven Professionals with over a Decade of Experience</h4> */}
              <p className="text-sm md:text-base mb-3 md:mb-6">With a mix of technical and business skills, our team can work with you to find the best way to apply cutting-edge technology while following your commercial and operational considerations.</p>
              <Link to="/our-people" className="text-sm md:text-base inline-flex items-center bg-primary text-white py-2 px-4">
                Our People
                <IconArrow className="inline ml-2 fill-white" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-primary py-8 md:py-20">
        <div className="absolute top-0 left-0 right-0 mx-auto text-center">
          <StaticImage src="../images/home-section-bg-2.png" alt="section"  />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>DPV: Tech Specialists & Providers of Business Management Solutions</title>
    <meta name="description" content="DPV provides technology services in analytics and machine learning." />
    <meta name="google-site-verification" content="ZPNXKg-FgdOCFAyC9SYaZ2Lqbs3fnnJ-XVTlFOX1O1E" />
  </>
)
