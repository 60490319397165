import React from "react"
import { Link } from "gatsby"
import IconArrow from "../assets/arrow-right.svg"

export default function MainVisual({children, title, description, buttonText, buttonLink, buttonOnClick}) {
  return (
    <section className="relative">
      {children}
      <div className="container absolute top-0 bottom-0 left-0 right-0 m-auto flex items-center">
        <div className="bg-primary bg-opacity-60 md:bg-opacity-80 p-4 md:py-8 md:px-6 inline-block max-w-2xl text-center md:text-left mx-auto md:mx-0">
          <h1 className="text-white text-xl md:text-6xl font-semibold">{title}</h1>
          <h2 className="text-tertiary text-lg md:text-4xl">{description}</h2>
          {
            buttonText && buttonLink && (
              <Link to={buttonLink} className="text-sm md:text-2xl inline-flex items-center text-primary bg-tertiary py-1 px-3 mt-3 mt:mb-6">
                {buttonText}
                  <IconArrow className="inline ml-2 fill-primary" />
              </Link> 
            )
          }
          {
            buttonText && buttonOnClick && (
              <button
                type="button"
                className="text-sm md:text-2xl inline-flex items-center text-primary bg-tertiary py-1 px-3 mt-3 mt:mb-6"
                onClick={buttonOnClick}
              >
                {buttonText}
                  <IconArrow className="inline ml-2 fill-primary" />
              </button> 
            )
          }
        </div>
      </div>
    </section>
  )
}