import React, { forwardRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../button"

const SectionCapabilities = forwardRef((props, ref) => {
  return (
    <section className="relative bg-light py-8 md:py-20" ref={ref}>
      <div className="container mx-auto">
        <h3 className="text-primary text-2xl md:text-5xl font-semibold text-center mb-6 md:mb-12">DPV Capabilities</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
          <div className="text-center">
            <StaticImage src="../../images/home-cap-2.png" className="mb-4" />
            <div className="text-primary text-xl md:text-3xl font-bold mb-4">Business Analytics</div>
            <Button link="/our-capabilities">Read More</Button>
          </div>
          <div className="text-center">
            <StaticImage src="../../images/home-cap-1.png" className="mb-4" />
            <div className="text-primary text-xl md:text-3xl font-bold mb-4">Machine Learning</div>
            <Button link="/our-capabilities">Read More</Button>
          </div>
          {/*
          <div className="text-center">
            <StaticImage src="../../images/home-cap-3.png" className="mb-4" />
            <div className="text-primary text-xl md:text-3xl font-bold mb-4">Web 3.0 & Decentralized Systems</div>
            <Button link="/our-capabilities">Read More</Button>
          </div>
        */}
        </div>
      </div>
      <div className="absolute top-0 left-0 right-0 mx-auto max-w-3xl">
        <StaticImage src="../images/home-section-bg-1.png" alt="section" />
      </div>
    </section>
  )
})

export default SectionCapabilities